import { Stage } from "./webgl/stage";
import { Mesh } from "./webgl/mesh";
import * as THREE from "three";

export class WebGL {
    constructor() {
        this.rafEnd = false;
        this.meshList = [];
        this.init();
    }

    init() {
        const canvas = document.querySelector(".js-noise_webglcanvas");
        //console.log(canvas);

        if (!canvas) {
            return;
        }

        const stage = new Stage(canvas);
        stage.init();

        const mesh = new Mesh(stage);
        this.meshList.push(mesh);
        mesh.init();

        window.addEventListener("resize", () => {
            stage.onResize();

            this.meshList.forEach((mesh) => {
                mesh.onResize();
            });
        });

        const clock = new THREE.Clock();
        clock.start();

        const raf = () => {
            const elapsed = clock.getElapsedTime();

            stage.onRaf();
            this.meshList.forEach((mesh) => {
                mesh.onRaf(elapsed);
            });

            if (!this.rafEnd) {
                requestAnimationFrame(raf);
            }
        };

        raf();
    }

    destroy() {
        //console.log("WebGL Destroy");
        this.rafEnd = true;
        this.meshList.forEach((el) => el.destroy());
        this.stage = null;
    }
}
