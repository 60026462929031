const categorySelect = () => {
    const trigger = document.querySelector(".js-categoryselect_trigger");
    const triggerClose = document.querySelector(".js-categoryselect_close");
    const wrapper = document.querySelector(".js-categoryselect");

    if (!trigger || !triggerClose || !wrapper) {
        return;
    }

    //外側で閉じる
    document.body.addEventListener("click", (e) => {
        if (e.target.closest(".js-categoryselect") === null && wrapper.getAttribute("data-select_active") === "1") {
            wrapper.setAttribute("data-select_active", "0");
        }
    });

    trigger.addEventListener("click", (e) => {
        wrapper.setAttribute("data-select_active", "1");
    });

    triggerClose.addEventListener("click", (e) => {
        wrapper.setAttribute("data-select_active", "0");
    });
};

export { categorySelect };
