const loadBgImage = () => {
    const bgElm = document.querySelector(".c-single_disc__bgph");

    if (bgElm) {
        const imgElm = bgElm.querySelector("img");
        //console.log(imgElm.getAttribute("data-src"));
        const loadimg = document.createElement("img");
        loadimg.src = imgElm.getAttribute("data-src");

        loadimg.onload = () => {
            bgElm.setAttribute("data-ready", "1");
            //console.log("load End");
        };
    }
};

export { loadBgImage };
