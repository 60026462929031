//functions
import { gnav } from "./functions/gnav.es6";
import { categorySelect } from "./functions/categorySelect.es6";
import { cursor } from "./functions/cursor.es6";
import { ttlAnim } from "./functions/ttlAnim.es6";
import { setCSSVariables } from "./functions/setCSSVariables.es6";
import { loadBgImage } from "./functions/loadBgImage.es6";

//classess
import ScrollFunc from "./classes/scrollfunc.es6";
import { WebGL } from "./classes/webgl.es6";
import { WebGLElm } from "./classes/webgl_elm.es6";

export default class Main {
    constructor() {
        this.scrollFunc = new ScrollFunc();
        this.init();
    }

    init() {
        if (!document.body.classList.contains("top")) {
            this.webgl = new WebGL();
            this.webglElm = new WebGLElm();
        }

        this.setFuncs();
    }

    setFuncs() {
        gnav();
        categorySelect();
        cursor();
        ttlAnim();
        setCSSVariables();
        loadBgImage();
    }
}
