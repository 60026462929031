import * as THREE from "three";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Utility } from "../../../util/utility";

import vs from "./shaders/vert.glsl";
import fs from "./shaders/frag.glsl";

export class Mesh {
    constructor(elementOption, stage) {
        this.geometryParm = {
            width: 1,
            height: 1,
            widthSegments: 1,
            heightSegments: 1,
        };
        this.elementOption = elementOption;
        this.textureLoader = new THREE.TextureLoader();

        this.materialParam = {
            useWireframe: false,
        };

        //window
        this.windowWidth = 0;
        this.windowHeight = 0;
        this.windowWidthHalf = 0;
        this.windowHeightHalf = 0;

        //status
        this.texture = null;
        this.geometry = null;
        this.material = null;
        this.mesh = null;
        this.stage = stage;

        this.reveal = false;

        this.uniforms = {
            uTime: {
                value: 0.0,
            },
            uTexture: {
                value: null,
            },
            uPower: {
                value: 1.0,
            },
        };
    }

    init() {
        this.setWindowSize();
        this.setMesh();
    }

    setWindowSize() {
        const size = this.stage.getCVsize();
        this.windowWidth = size.width;
        this.windowHeight = size.height;
        this.windowWidthHalf = this.windowWidth * 0.5;
        this.windowHeightHalf = this.windowHeight * 0.5;
    }

    setScrollTrigger() {
        ScrollTrigger.create({
            trigger: this.elementOption.$target,
            start: "top 85%",
            //markers: true,
            onEnter: () => {
                this.reveal = true;
            },
        });
    }

    setMesh() {
        this.geometry = new THREE.PlaneBufferGeometry(this.geometryParm.width, this.geometryParm.height, this.geometryParm.widthSegments, this.geometryParm.heightSegments);

        this.textureLoader.load(
            this.elementOption.src,
            (assets) => {
                this.material = new THREE.ShaderMaterial({
                    vertexShader: vs,
                    fragmentShader: fs,
                    wireframe: this.materialParam.useWireframe,
                    uniforms: this.uniforms,
                });

                // this.material = new THREE.MeshBasicMaterial({
                //     color: 0xffffff,
                // });

                this.uniforms.uTexture.value = assets;
                this.mesh = new THREE.Mesh(this.geometry, this.material);
                this.stage.scene.add(this.mesh);
                this.setMeshScale();
                this.setMeshPosition();
                this.setScrollTrigger();
            },
            undefined,
            (err) => {
                console.log("load error");
            }
        );
    }

    setMeshScale() {
        this.mesh.scale.x = this.elementOption.width;
        this.mesh.scale.y = this.elementOption.height;
        this.meshWidthHalf = this.mesh.scale.x * 0.5;
        this.meshHeightHalf = this.mesh.scale.y * 0.5;
    }

    setMeshPosition() {
        //canvasサイズと要素サイズを一致させるので、positionは今回0でよい
        //this.mesh.position.y = this.windowHeightHalf - this.meshHeightHalf - this.elementOption.offset.top;
        //this.mesh.position.x = -this.windowWidthHalf + this.meshWidthHalf + this.elementOption.offset.left;
    }

    onResize() {
        this.setWindowSize(); // windowのサイズ,uniformsをセット
        this.setMeshScale(this.windowWidth, this.windowHeight); // meshのサイズをセット
        this.setMeshPosition();
    }

    onRaf(elapsed) {
        this.uniforms.uTime.value = elapsed;

        if (this.reveal && !this.animated) {
            //In Animation
            gsap.to(this.uniforms.uPower, { value: 0, duration: Utility.isSP() ? 1 : 1.5, ease: Utility.isSP() ? "circ.out" : "circ.out" });
            this.animated = true;
        }
    }

    destroy() {
        this.stage.scene && this.stage.scene.remove(this.mesh);
        this.geometry && this.geometry.dispose();
        this.material && this.material.dispose();
        this.mesh && this.mesh.material.dispose();
        this.mesh && this.mesh.geometry.dispose();
        this.texture && this.texture.dispose();
        //console.log("Mesh destroy");
    }
}
