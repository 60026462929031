const gnav = () => {
    const trigger = [...document.querySelectorAll(".js-gnav_open")];
    let isOpen = false;

    trigger.forEach((el) => {
        el.addEventListener("click", (e) => {
            if (document.body.getAttribute("data-gnavactive") === "1") {
                document.body.setAttribute("data-gnavactive", "0");
            } else {
                document.body.setAttribute("data-gnavactive", "1");
                isOpen = true;
            }
        });
    });

    //外側で閉じる
    document.body.addEventListener("click", (e) => {
        if (e.target.closest(".js-gnav") === null && e.target.closest(".js-gnav_open") === null) {
            document.body.setAttribute("data-gnavactive", "0");
        }
    });
};

export { gnav };
