import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.setScrollTrigger();
    }

    setScrollTrigger() {
        const revealElm = [...document.querySelectorAll(".js-scrollreveal")];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 85%",
                //markers: true,
                onEnter: () => {
                    el.setAttribute("data-reveal", "1");
                },
            });
        });
    }
}
