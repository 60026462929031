import * as THREE from "three";

import { Stage } from "./webgl_elm/stage";
import { Mesh } from "./webgl_elm/mesh";
import { GlElements } from "./webgl_elm/gl_elements.es6";

export class WebGLElm {
    constructor() {
        this.rafEnd = false;
        this.meshList = [];
        this.stageList = [];
        this.init();
    }

    init() {
        const glElements = new GlElements();
        glElements.init();

        if (glElements.optionList.length === 0) {
            //GLElementsがないページでは実行しない
            this.destroy();
            return;
        }

        glElements.optionList.forEach((item) => {
            const canvas = document.createElement("canvas");
            canvas.classList.add("l-canvas_elm");
            item.$target.parentNode.append(canvas);

            const stage = new Stage(canvas);
            stage.init();
            this.stageList.push(stage);

            const mesh = new Mesh(item, stage);
            mesh.init();
            this.meshList.push(mesh);
        });

        window.addEventListener("resize", () => {
            glElements && glElements.onResize();

            this.meshList.forEach((mesh) => {
                mesh.onResize();
            });
            this.stageList.forEach((stage) => {
                stage.onResize();
            });
        });

        const clock = new THREE.Clock();
        clock.start();

        const raf = () => {
            const elapsed = clock.getElapsedTime();

            this.meshList.forEach((mesh) => {
                mesh.onRaf(elapsed);
            });

            this.stageList.forEach((stage) => {
                stage.onRaf();
            });

            if (!this.rafEnd) {
                requestAnimationFrame(raf);
            }
        };

        raf();
    }

    destroy() {
        //console.log("WebGL Destroy");
        this.rafEnd = true;
        this.meshList.forEach((el) => el.destroy());
        this.stage = null;
    }
}
