import { Utility } from "../../util/utility";

const setCSSVariables = () => {
    //Single Discでの背景高さ表示
    const singleDiscMain = document.querySelector(".c-single_disc_main");
    const bgPh = document.querySelector(".c-single_disc__bgph");
    const jkImg = document.querySelector(".c-single_disc__jk img");

    const setHeight = () => {
        if (!bgPh) {
            return;
        }
        if (Utility.isSP()) {
            bgPh.style.setProperty("--height", singleDiscMain.offsetHeight - jkImg.offsetHeight * 0.5 + "px");
        } else {
            bgPh.style.setProperty("--height", singleDiscMain.offsetHeight + "px");
        }
    };

    if (singleDiscMain) {
        setHeight();
        window.addEventListener("resize", setHeight);
        window.addEventListener("load", setHeight);
    }
};

export { setCSSVariables };
