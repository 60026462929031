import * as THREE from "three";

export class Stage {
    constructor(canvas) {
        this.canvas = canvas;
        this.cameraV = 0.0000003;

        this.rendererParam = {
            clearColor: 0x000000,
            width: this.getCVsize().width,
            height: this.getCVsize().height,
        };

        this.cameraParam = {
            fov: 60,
            near: 0.1,
            far: 10.0,
            lookAt: new THREE.Vector3(0, 0, 0),
            x: 0,
            y: 0,
            z: 1,
        };

        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.geometry = null;
        this.material = null;
        this.mesh = null;
        this.nrw = window.innerWidth <= 834;

        this.isInitialized = false;
    }

    init() {
        this.setScene();
        this.setRenderer();
        this.setCamera();

        this.isInitialized = true;
    }

    getCVsize() {
        const w = window.innerWidth;
        const h = window.innerHeight;
        return { width: w, height: h };
    }

    setScene() {
        this.scene = new THREE.Scene();
    }

    setRenderer() {
        const canvas = this.canvas;
        this.renderer = new THREE.WebGLRenderer({ canvas: canvas, alpha: true });
        this.renderer.setPixelRatio(window.devicePixelRatio < 2 ? 1.5 : Math.min(2, window.devicePixelRatio));
        this.renderer.setClearColor(new THREE.Color(this.rendererParam.clearColor), 0.0);
        this.renderer.setSize(this.rendererParam.width, this.rendererParam.height);
    }

    setCamera() {
        if (!this.isInitialized) {
            this.camera = new THREE.PerspectiveCamera(
                this.cameraParam.fov, // fov
                0, // aspect
                this.cameraParam.near,
                this.cameraParam.far
            );

            this.camera.position.set(this.cameraParam.x, this.cameraParam.y, this.cameraParam.z);
            this.camera.lookAt(this.cameraParam.lookAt);
        }

        const windowWidth = this.getCVsize().width;
        this.camera.aspect = windowWidth / this.getCVsize().height;
        this.camera.fov = THREE.MathUtils.radToDeg(Math.atan(windowWidth / this.camera.aspect / (2 * this.camera.position.z))) * 2;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(windowWidth, this.getCVsize().height);
        //console.log("stage Resize");
    }

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    onResize() {
        this.setCamera();
    }

    onRaf() {
        this.render();
    }
}
