class SplitText {
    //　クラス宣言
    constructor(els, className) {
        //定義したクラスからオブジェクトを生成
        this.els = els; //NodeListを渡す
        this.className = className;
        this.els.forEach((el) => {
            this.chars = el.textContent.trim();
            this.result = "";
            el.innerHTML = this._splitText();
        });
    }
    //テキスト分割のメソッドを定義
    _splitText() {
        for (let c of this.chars) {
            c = c.replace(" ", "&nbsp;");
            this.result += `<span class="${this.className}">${c}</span>`;
        }
        return this.result;
    }
}

const ttlAnim = () => {
    const title = document.querySelectorAll(".js-splittext");
    const className = "";
    const splitText = new SplitText(title, className);
};

export { ttlAnim };
